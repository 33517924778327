.chart {
    display: flex;
    justify-content: center;
}
.title {
    padding: 20px;
    font-weight: bold;
    font-size: 17px;
}
.error {
    color: red;
}